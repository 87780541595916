*,
*::after,
*::before {
  box-sizing: border-box;
}
body {
  margin: 0px;
}

@font-face {
  font-display: swap;
  font-family: "Chronicle Deck";
  font-weight: normal;
  src: url("../../assets/fonts/ChronicleDeck-Roman.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Circular";
  font-weight: 300;
  src: url("../../assets/fonts/CircularStd-Book.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Circular";
  font-weight: 500;
  src: url("../../assets/fonts/CircularStd-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Circular";
  font-weight: bold;
  src: url("../../assets/fonts/CircularStd-Bold.otf") format("opentype");
}